import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {  
  constructor(props) {
    super(props)
    this.childDiv = React.createRef()
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.handleScroll();
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  handleScroll = () => {
    const { index, selected } = this.props
    if (index === selected) {
      setTimeout(() => {
        this.childDiv.current.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }
  }
  render() {
    const { children } = this.props;
    return <main ref={this.childDiv}>{children}</main>;
  }
} 

export default withRouter(ScrollToTop);