import './contact.scss';
import React, { Component } from "react";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import MailIcon from '@material-ui/icons/Mail';
import { withHub } from '../../Hub';
import { Trans, withTranslation } from 'react-i18next';

class Contact extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="Contact">
                <Header></Header>
                <div className="content">
                    <h1>{t('screen.contact.title')}</h1>
                    <Trans i18nKey="screen.contact.content">
                        <p>Do you have questions about our platform, want to give us feedback or write a press article about us? <br /><br />Write us an email. We will answer as soon as we can.</p>
                    </Trans>
                    <div className="contact-item">
                        <MailIcon></MailIcon>
                        <a href = "mailto: contact@dailyvents.de">contact@dailyvents.de</a>
                    </div>
                   
                </div>
                <Footer></Footer>

            </div>
        );
    }
}
export default withTranslation()(withHub(Contact));
