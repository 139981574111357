import './privacy.scss';
import React, { Component } from "react";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { withTranslation } from 'react-i18next';
import { withHub } from '../../Hub';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import img1 from '../../Img/horizontal_clear.svg';


class Privacy extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="Contact">
                <Header></Header>
                <div className="content">
                    <img className='inner-logo' src={img1} />
                    <h1>{t('screen.privacy-website.title')}</h1>
                    <ReactMarkdown children={t('screen.privacy-website.content')} remarkPlugins={[remarkGfm]} />
                </div>

                <Footer></Footer>
            </div>
        );
    }
}
export default withTranslation()(withHub(Privacy));
