import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import AppleIcon from '@material-ui/icons/Apple';
import ShopIcon from '@material-ui/icons/Shop'
import { withRouter } from "react-router-dom";
import qrcode from '../../Img/qrcode.webp'
import { withTranslation } from "react-i18next";

class JoinIn extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="join">
                <div className="qr-code">

                    <img className="logo" src={qrcode} alt="" />

                </div>
                <h2>{t('screen.home.join-in.title')}</h2>
                <div>
                    <Button variant="contained" color="primary" target="_blank" href="https://apps.apple.com/us/app/dailyvents/id6447474654"><AppleIcon className="inner-icon"></AppleIcon>App Store</Button>
                    <Button variant="contained" color="secondary" target="_blank" href='https://play.google.com/store/apps/details?id=com.dailyvents.prod'><ShopIcon className="inner-icon"></ShopIcon>Google Play</Button>
                </div>
            </div>
        );
    }
}
export default withTranslation()(withRouter(JoinIn));
