import React, { Component } from "react";
import img4 from '../../Img/field.jpg';
import img5 from '../../Img/d-logo.png';
// import soccer from '../../Img/soccer.png'
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Partnership extends Component {
  render() {
    const { t } = this.props;
    return (
      // <div className="Partnership">
      //     <div>
      //         <div className="left">
      //             <h1>{t('screen.home.partnership.main-title')}</h1>
      //             <h3>{t('screen.home.partnership.title')}</h3>
      //             <p className="big">{t('screen.home.partnership.subtitle')}</p>
      //             <img src={img4} alt={t('screen.home.partnership.subtitle')} />
      //             <Button variant="contained" color="primary" onClick={() => this.props.history.push({pathname: "/partner"})}>
      //                 {t('screen.home.partnership.button')} 
      //             </Button>
      //         </div>
      //         <div className="right">
      //             <img className="soccer" src={soccer} alt={t('screen.home.partnership.main-title')} />
      //         </div>
      //     </div>
      // </div>
      <div className="content-two">
        <div className="left">
          <img className='daily-logo' src={img5} />
          <img className='field-bg' src={img4} />
          <div className='overlay'></div>
        </div>
        <div className="right">
          <div className='text'>
            <h1>{t('screen.home.partnership.main-title')}</h1>
            <h3>{t('screen.home.partnership.title')}</h3>
            <p>
              {t('screen.home.partnership.subtitle')}
            </p>
            <Button variant="contained" color="primary" onClick={() => this.props.history.push({ pathname: "/contact" })}>
              {t('screen.home.partnership.button')}
            </Button>
          </div>

        </div>
      </div>
    );
  }
}
export default withTranslation()(withRouter(Partnership));
