import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { CULTURE_LIST } from './DataSource/Mocks/constants';
 
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === "development",
 
    fallbackLng: 'en',
    whitelist: CULTURE_LIST,
 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/add/{{lng}}/{{ns}}',
    }
  });
 
export default i18n;