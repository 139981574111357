import img1 from '../../Img/col1.png';
import img2 from '../../Img/col2.png';
import img3 from '../../Img/col3.png';

import ic1 from '../../Img/ic1.png';
import ic2 from '../../Img/ic2.png';
import ic3 from '../../Img/ic3.png';
// import img2 from '../../Img/img2.svg';
// import img3 from '../../Img/img3.svg';
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';



class HowWorks extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="HowWorks" id="how">
        <div className="content">
          <h1>{t('screen.home.how-works.title')}</h1>
          {/* <div className="">
            <div className="box">
              <h2>{t('screen.home.how-works.box.search.title')}</h2>
              <p className="">{t('screen.home.how-works.box.search.content')}</p>
              <img src={img3} alt={t('screen.home.how-works.box.search.title')} />
            </div>
            <div className="box">
              <h2>{t('screen.home.how-works.box.choose.title')}</h2>
              <p className="">{t('screen.home.how-works.box.choose.content')}</p>
              <img src={img2} alt={t('screen.home.how-works.box.choose.title')} />
            </div>
            <div className="box">
              <h2>{t('screen.home.how-works.box.join.title')}</h2>
              <p className="">{t('screen.home.how-works.box.join.content')}</p>
              <img src={img1} alt={t('screen.home.how-works.box.join.title')} />
            </div>
          </div> */}
          <div className="inner">
            <div className="col">
              <div className='bullit'>
                <div className="number red-gradient"><h2>1</h2></div>
                <div className='text'>
                  <h3>{t('screen.home.how-works.box.search.title')}</h3>
                  <p>{t('screen.home.how-works.box.search.content')}</p>
                </div>
              </div>
              <div className='demo'>
                <img className='ic' src={ic3} />
                <img src={img1} />
              </div>
            </div>

            <div className="col">
              <div className='bullit'>
                <div className="number red-gradient"><h2>2</h2></div>
                <div className='text'>
                  <h3>{t('screen.home.how-works.box.choose.title')}</h3>
                  <p>{t('screen.home.how-works.box.choose.content')}</p>
                </div>
              </div>
              <div className='demo'>
                <img className='ic' src={ic2} />
                <img src={img2} />
              </div>
            </div>

            <div className="col">
              <div className='bullit'>
                <div className="number red-gradient"><h2>3</h2></div>
                <div className='text'>
                  <h3>{t('screen.home.how-works.box.join.title')}</h3>
                  <p>{t('screen.home.how-works.box.join.content')}</p>
                </div>
              </div>
              <div className='demo'>
                <img className='ic' src={ic1} />

                <img src={img3} />
              </div>
            </div>



          </div>

        </div>


       
      </div>
    );
  }
}

export default withTranslation()(HowWorks);
