import React from "react";
import { Route, BrowserRouter } from "react-router-dom";
import Home from "./Screens/Home/index";
import Contact from "./Screens/Contact/index";
import Imprint from "./Screens/Imprint";
import Privacy from "./Screens/Privacy";
import ScrollToTop from "./ScrollToTop";
import PrivacyApp from "./Screens/PrivacyApp";
import Terms from "./Screens/Terms";
import PrivacyUser from "./Screens/PrivacyUser";

const Routes = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Route component={Home} path="/" exact />
                <Route component={Contact} path="/contact" />
                <Route component={Imprint} path="/imprint" />
                <Route component={Privacy} path="/privacy-website" />
                <Route component={PrivacyApp} path="/privacy" />
                <Route component={PrivacyUser} path="/privacy-user" />
                <Route component={Terms} path="/terms" />
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default Routes;