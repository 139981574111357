import './App.scss';
import Routes from "./Routes";
import React, { Component } from "react";
import { withHub } from "./Hub";
import { FULLSCREEN_LOADER } from './Events';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    }
  }
  
  componentDidMount() {
    this.props.attach(FULLSCREEN_LOADER, params => {
      this.setState({ isLoading: params.isLoading });
    });
  }

  render() {
    return (
      <div className="App">
        {this.state.isLoading && <div className="loader fixed" />}
        <Routes />
      </div>
    );
  }
}

export default withHub(App);
