import './home.scss';
import React, { Component } from "react";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import How from './HowWorks';
import Video from './Video';
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import { withHub } from '../../Hub';
import JoinIn from '../../Components/JoinIn/JoinIn';
import Partnership from './Partnership';





class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            currentSportCategory: 'all',
            currentDate: Date.now(),
            currentLocation: { properties: { city: props.t('screen.home.main.standard-city') }, geometry: { coordinates: [11.58, 48.14] } }
        }
    }

    render() {
        return (
            <div className="Home">
                <Header />
                <div className='landing'>
                    <div className='header-bg'></div>

                    <div className="video-container">
                        <div className='overlay'></div>
                        <Video />
                    </div>                    
                    <div className='actions'>
                        <JoinIn />
                    </div>
                </div>
                <How />
                <Partnership/>
                <Footer />
            </div>
        );
    }
}
export default withTranslation()(withRouter(withHub(Home)));

