import './footer.scss';
import React, { Component } from 'react';
import logoclear from '../../Img/horizontal_clear.svg';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Link, withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { withHub } from '../../Hub';

class Footer extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="Footer">
                <img src={logoclear} alt="logo_clear" />
                <div className="footer-inner">
                    <div className="shortcuts">
                        <ul>
                            <li><Link to="/#">{t('component.footer.home')}</Link></li>
                            <li><Link to="/#how">{t('component.footer.how-it-works')}</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="/contact">{t('component.footer.contact')}</Link></li>
                            <li><Link to="/privacy-website">{t('component.footer.privacy-website')}</Link></li>
                            <li><Link to="/privacy">{t('component.footer.privacy-app')}</Link></li>
                            <li><Link to="/imprint">{t('component.footer.imprint')}</Link></li>
                        </ul>
                        <div className="separator"></div>
                    </div>
                    <div className="social">
                        <p>{t('component.footer.social-media')}</p>
                        <div>
                            <a href="https://www.instagram.com/dailyventsofficial/" target="_blank" rel="noreferrer"><InstagramIcon></InstagramIcon></a>
                            <a href="https://www.facebook.com/dailyventsofc" target="_blank" rel="noreferrer"><FacebookIcon></FacebookIcon></a>
                            <a href="https://twitter.com/dailyventsofc" target="_blank" rel="noreferrer"><TwitterIcon></TwitterIcon></a>
                        </div><p>
                        <Trans i18nKey="component.footer.disclaimer" />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withHub(withRouter(Footer)));


