import './header.scss';
import HomeIcon from '@material-ui/icons/Home';
import ContactsIcon from '@material-ui/icons/Contacts';
import logo from '../../Img/horizontal_filled.svg';
import React, { Component } from "react";
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import { CULTURE_LIST } from '../../DataSource/Mocks/constants';
import { withTranslation } from 'react-i18next';
import { withHub } from '../../Hub';
import { LANGUAGE_CHANGED } from '../../Events';

function MenuText(props) {
  return (props.isMobile ? (<p>{props.text}</p>) : props.text)
}

function Menu(props) {
  const changeCulture = culture => {
    props.i18n
      .changeLanguage(culture)
      .then(() => { 
        props.notify(LANGUAGE_CHANGED, { isLanguageChanged: true, language: culture })
      });
  };

  return (
    <div className="right">
      <Link to="/">
        {props.isMobile && <HomeIcon />}
        <MenuText text={props.t('component.header.home')} isMobile={props.isMobile} />
      </Link>
      <Link to="/contact#">
        {props.isMobile && <ContactsIcon />}
        <MenuText text={props.t('component.header.contact')} isMobile={props.isMobile} />
      </Link>
      <FormControl>
        {props.isMobile && <LanguageIcon />}
        <Select id="grouped-select"
          value={props.i18n.language}
          onChange={(event) => {
            changeCulture(event.target.value);
          }}>
          {
            CULTURE_LIST.map((value, index) => <MenuItem value={value} key={index}>{value.toLocaleUpperCase()}</MenuItem>)
          }
        </Select>
      </FormControl>
    </div>
  );
}

class Header extends Component {
  render() {
    return (
      <div className="Header">
        <div className="header mobile">
          <Menu
            {...this.props}
            becomePartnerLabel={"component.header.be-partner"}
            isMobile={true}
          ></Menu>
        </div>
        <div className="header">
          <Link to="/"> <img src={logo} alt="logo" /></Link>
          <Menu
            {...this.props}
            becomePartnerLabel={"component.header.become-a-partner"}
            isMobile={false}
          ></Menu>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withHub(Header));
