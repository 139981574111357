import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import sample0 from '../../Img/bg-img.png';
import sample from '../../Img/bg.mp4';
import sample2 from '../../Img/bg-mobile.mp4';
import sample3 from '../../Img/bg-mobile.webm';


class Video extends Component {
    
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 650;

        if (isMobile) {
            return (
                <video className='videoTag mobile' autoPlay loop data-keepplaying muted playsInline preload="yes" data-wf-ignore="true" data-object-fit="cover" poster={sample0}>
                    <source src={sample2} type='video/mp4' />
                    <source src={sample3} type='video/webm' />
                </video>
                

            );
        } else {
            return (
                <video className='videoTag' autoPlay loop muted playsInline preload="yes">
                    <source src={sample} type='video/mp4' />
                </video>
            );
        }
    }
}
export default withTranslation()(withRouter(Video));
